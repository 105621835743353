import React from 'react';

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && localStorage.getItem(localStorageKey)
      ? JSON.parse(localStorage.getItem(localStorageKey))
      : []
  );

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, JSON.stringify(value));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return [value, setValue];
};

export default useStateWithLocalStorage;
