import React, { useEffect, useState } from 'react';

import { navigate } from 'gatsby-link';

import { Article, Filters, Pagination } from 'components/blog';
import { ButtonSecondary } from 'components/button';

import { Wrapper } from './Blog.styles';

import useStateWithLocalStorage from 'utils/localStorage';

const Blog = (props) => {
  //PROPS
  const {
    data,
    location: { search, pathname },
  } = props;
  //PROPS

  //FUNCTIONS
  const checkParams = () => {
    const params = new URLSearchParams(props.location.search);

    const pagination = params.has('pagination') ? params.get('pagination') : 1;

    const filters = params.has('categorias') ? params.getAll('categorias') : [];
    const author = params.has('autores') ? params.getAll('autores') : [];
    const year = params.has('data') ? params.getAll('data') : [];

    const saveForLater = params.has('save-for-later');

    return { filters, pagination, author, year, saveForLater };
  };

  const updateFilters = (type, string) => {
    const params = new URLSearchParams(props.location.search);

    const handleParams = (type, val) => {
      if (params.has(type)) {
        if (params.getAll(type).includes(val)) {
          if (params.getAll(type).length === 1) {
            params.delete(type);
          } else {
            const allVals = params.getAll(type);
            params.delete(type);
            allVals
              .filter((e) => e !== val)
              .forEach((e) => params.append(type, e));
          }
        } else {
          params.append(type, val);
        }
      } else {
        params.set(type, val);
      }
    };

    switch (type) {
      case 'Categorias':
        handleParams('categorias', string);
        break;
      case 'Autores':
        handleParams('autores', string);
        break;
      case 'Data':
        handleParams('data', string);
        break;
      case 'save-for-later':
        for (const [key, value] of params.entries()) {
          if (key !== 'save-for-later') {
            params.delete(key);
          }
        }
        params.has('save-for-later')
          ? params.delete('save-for-later')
          : params.set('save-for-later', 'true');
        break;
    }

    navigate(
      pathname + params.toString().length ? '?' + params.toString() : ''
    );
  };

  const goPage = (number) => {
    const params = new URLSearchParams(props.location.search);

    params.set('pagination', number);

    navigate(
      pathname + params.toString().length ? '?' + params.toString() : ''
    );
  };

  //FUNCTIONS

  //STATES
  const [articles, setArticles] = useState([]);
  const [allData, setAllData] = useState([]);
  //STATES

  //EFFECTS
  useEffect(() => {
    const { filters, author, year, pagination, saveForLater } = checkParams();

    let filteredData = data;

    if (!pagination && !filters && !author && !year) {
      filteredData = data;
    }

    if (filters.length) {
      filteredData = filteredData.filter((el) =>
        el?.node?.categories?.nodes.some((r) => filters.indexOf(r.slug) >= 0)
      );
    }

    if (author.length) {
      filteredData = filteredData.filter((el) =>
        el?.node?.post.author?.some((r) => author.indexOf(r.slug) >= 0)
      );
    }

    if (year.length) {
      filteredData = filteredData.filter((el) =>
        el?.node?.post.date.includes(year)
      );
    }

    if (saveForLater) {
      filteredData = filteredData.filter((el) =>
        value.includes(el?.node?.slug)
      );
    }

    setAllData(filteredData);

    if (pagination) {
      filteredData = filteredData.slice((pagination - 1) * 7, pagination * 7);
    }

    setArticles(filteredData);
  }, [search]);
  //EFFECTS

  const [value, setValue] = useStateWithLocalStorage('bookmarked');

  return (
    <Wrapper>
      <div className="blog__toggle-filters">
        <ButtonSecondary
          onClick={() => {
            document
              .querySelector('.blog__filters')
              .classList.toggle('blog__filters--active');
          }}
        >
          Mostrar Filtros
        </ButtonSecondary>
      </div>
      <div className="blog__filters">
        <Filters
          updateFilters={updateFilters}
          checkParams={checkParams}
          data={data}
          path={search}
        />
      </div>
      <div className="blog__articles">
        <div className="blog__articles-container">
          {articles.map((article, index) => {
            return (
              <Article
                key={index}
                data={{ article, index }}
                value={value}
                setValue={setValue}
              />
            );
          })}
        </div>
        {allData.length > 7 && (
          <Pagination
            postsPerPage={7}
            totalPosts={allData.length}
            paginate={goPage}
            currentPage={Math.floor(checkParams().pagination)}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default Blog;
