import styled from 'styled-components';

import { space } from 'theme/sizes';
import { neutral, brand } from 'theme/colors';
import { breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: ${space.s};
  margin-bottom: ${space.l};

  & > button {
    margin: 0 ${space.xxs};
  }

  ul {
    order: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 ${space.xs};
    margin-bottom: ${space.s};

    li {
      margin: ${space.tiny};
      button {
        height: ${space.m};
        width: ${space.m};
        border-radius: 100%;
        background: ${neutral.neu04};

        &.active {
          color: ${neutral.neu01};
          background: ${brand.main};
        }
      }
    }
  }

  @media ${breakpoints.phablet} {
    & > button {
      margin: unset;
    }

    ul {
      order: unset;
      margin-bottom: 0;
    }
  }
`;
