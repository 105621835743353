import styled from 'styled-components';
import { Link } from 'gatsby';

import { neutral } from 'theme/colors';
import { breakpoints, gridSize, toRem } from 'utils/mixins';
import { container, space, fontSize } from 'theme/sizes';

export const Wrapper = styled.ul`
  width: 100%;

  & > button {
    margin-bottom: ${space.xs};
  }

  h4 {
    font-weight: 400;
    font-size: ${fontSize.xs};
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: ${space.xs};
  }

  .filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  & > li {
    margin-bottom: ${space.s};
  }

  @media ${breakpoints.laptop} {
    position: sticky;
    top: ${space.xxl};
    padding-bottom: ${space.l};

    & > button {
      display: none;
    }
  }
`;

export const Item = styled.li`
  position: relative;
  margin-bottom: ${space.xxs};
  margin-right: ${space.xxs};

  svg {
    margin-right: ${space.xxs};
  }

  &.active {
    button {
      border: 1px solid ${(props) => props.color || neutral.neu08};
    }
  }

  button {
    display: flex;
    align-items: center;
    padding-left: ${(props) => (props.hasColor ? toRem(32) : toRem(16))};
  }

  &::before {
    display: ${(props) => (props.hasColor ? 'block' : 'none')};
    z-index: 2;
    content: '';
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-4px);
    height: ${toRem(8)};
    width: ${toRem(8)};
    border-radius: ${toRem(8)};
    background-color: ${(props) => props.color};
  }
`;
