import styled from 'styled-components';

import { container, space } from 'theme/sizes';
import { neutral } from 'theme/colors';
import { gridSize, breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${container.default};
  padding: 0 ${space.xs};
  margin: 0 auto;

  .blog__toggle-filters {
    margin-bottom: ${space.s};
  }

  .blog__filters {
    z-index: 4;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: ${neutral.neu02};
    width: ${gridSize(8)};
    padding: ${space.header} ${space.s};
    transform: translateX(-100%);
    transition: 220ms;

    &--active {
      transform: translateX(0);
    }
  }

  .blog__articles {
    width: ${gridSize(8)};

    &-container {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media ${breakpoints.laptop} {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .blog__toggle-filters {
      display: none;
    }

    .blog__filters {
      position: relative;
      width: ${gridSize(2)};
      transform: unset;
      padding: unset;
      background: unset;
      height: unset;
    }

    .blog__articles {
      width: ${gridSize(6)};
    }
  }
`;
