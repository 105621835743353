import styled from 'styled-components';

import { neutral, brand } from 'theme/colors';
import { space } from 'theme/sizes';
import { h3, h4 } from 'theme/typography';
import { gridSize, toRem, breakpoints } from 'utils/mixins';

export const Wrapper = styled.article`
  position: relative;
  width: ${gridSize(8)};
  margin: 0 auto;
  margin-bottom: ${space.l};

  .article__badges {
    z-index: 1;
    top: 0;
    left: 0;
    padding: ${space.xs};
    width: 100%;
    position: absolute;
    display: flex;
    flex-wrap: wrap;

    li {
      margin-bottom: ${space.xxs};
      margin-right: ${space.xxs};
    }
  }

  .article__image {
    position: relative;
    width: 100%;
    padding-bottom: 66%;
    margin-bottom: ${space.xs};

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    object-fit: cover;
    max-height: ${toRem(410)};
    margin-bottom: ${space.xs};
  }

  .article__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${space.xs};

    &-author {
      margin: 0;
      color: ${neutral.neu07};
    }

    &-bookmark {
    }
  }

  h2 {
    ${h3}
    font-weight: bold;
    margin: 0;
    margin-bottom: ${space.xs};
  }

  .article__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      text-decoration: none;
      ${h4}
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: ${toRem(2)};
        background-color: ${brand.main};
      }
    }
  }

  @media ${breakpoints.laptop} {
    width: ${(props) => (props.isFirst ? gridSize(8) : gridSize(4))};
    margin: ${(props) => (props.isFirst ? '0 auto' : 'unset')};
    margin-bottom: ${space.l};

    &:nth-child(2n) {
      margin-right: ${(props) => (props.imageUrl ? '' : toRem(20))};
    }
  }
`;

export const Badge = styled.button`
  position: relative;
  display: block;
  padding: ${space.xxs} ${space.xs} ${space.xxs} ${toRem(20)};
  color: ${neutral.neu10};
  text-transform: uppercase;
  font-size: ${toRem(12)};
  border-radius: ${toRem(4)};
  background-color: ${neutral.neu01};
  border: ${toRem(1)} solid ${(props) => props.color};

  &:not(:last-child) {
    margin-right: ${space.xxs};
  }

  &::before {
    content: '';
    position: absolute;
    left: ${space.xxs};
    top: 50%;
    transform: translateY(-4px);
    height: ${toRem(6)};
    width: ${toRem(6)};
    border-radius: ${space.xxs};
    background-color: ${(props) => props.color};
  }
`;
