import React from 'react';

import { Link } from 'gatsby';
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs';
import HTMLReactParser from 'html-react-parser';
import { GatsbyImage } from 'gatsby-plugin-image';

import COLORS from 'data/articles-colors.json';

import { Wrapper, Badge } from './Article.styles';

import { secondary } from 'theme/colors';

const Article = (props) => {
  //PROPS
  const {
    imageUrl,
    data: {
      article: {
        node: {
          slug,
          categories: { nodes },
          post: { thumbText, thumbTitle, thumbImage, date, author },
        },
      },
      index,
    },
    value,
    setValue,
  } = props;
  //PROPS

  return (
    <Wrapper isFirst={index === 0} imageUrl={imageUrl}>
      <ul className="article__badges">
        {nodes.map((category, index) => {
          const { name, slug } = category;
          return (
            <li key={index}>
              <Badge color={secondary[COLORS[slug]]}>{name}</Badge>
            </li>
          );
        })}
      </ul>
      {imageUrl ? (
        <div className="article__image">
          <img src={thumbImage?.mediaItemUrl} alt={thumbImage?.altText} />
        </div>
      ) : (
        <GatsbyImage
          image={thumbImage?.localFile?.childImageSharp?.gatsbyImageData}
          alt={thumbImage?.altText}
        />
      )}
      <div className="article__header">
        {(date || author?.[0]) && (
          <p className="article__header-author">
            {author?.[0]?.title}
            {date && author?.[0] && ' • '}
            {date}
          </p>
        )}
        {value?.includes(slug) ? (
          <button
            className="article__header-bookmark"
            onClick={() => {
              setValue([
                ...value.filter((e) => {
                  return e !== slug;
                }),
              ]);
            }}
          >
            <BsBookmarkFill />
          </button>
        ) : (
          <button
            className="article__header-bookmark"
            onClick={() => setValue([...value, slug])}
          >
            <BsBookmark />
          </button>
        )}
      </div>
      <h2>{thumbTitle}</h2>
      {thumbText && HTMLReactParser(thumbText)}
      <div className="article__footer">
        <Link to={'/blog/' + slug}>Continuar a ler</Link>
      </div>
    </Wrapper>
  );
};

export default Article;
