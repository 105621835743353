import React, { useEffect, useState } from 'react';

import { BsArrowLeft, BsBookmark, BsBookmarkFill } from 'react-icons/bs';

import { ButtonGeneric, ButtonSecondary } from 'components/button';

import { Wrapper, Item } from './Filters.styles';

import COLORS from 'data/articles-colors.json';

import { secondary } from 'theme/colors';

const Filters = (props) => {
  const { updateFilters, checkParams, path, data } = props;

  const [activeFilters, setActiveFilters] = useState([]);
  const [filters, setFilters] = useState([]);

  const getFilters = () => {
    let authors = [];
    let categories = [];
    let date = [];

    let aArr = [];
    data.forEach((post) => {
      aArr.push(post.node.post.author?.[0]);
    });
    authors = aArr
      .filter((n) => n)
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t?.slug === value?.slug)
      );

    let cArr = [];
    data.forEach((post) => {
      post.node.categories.nodes.forEach((e) => cArr.push(e));
    });
    categories = cArr
      .filter((n) => n)
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t?.slug === value?.slug)
      )
      .map((e) => {
        return { ...e, color: COLORS[e.slug] };
      });

    let dArr = [];
    data.forEach((post) => {
      const year = post.node.post.date && post.node.post.date.split('/')[2];
      year && dArr.push({ name: year, slug: year });
    });
    date = dArr
      .filter((n) => n)
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t?.slug === value?.slug)
      );

    return [
      {
        type: 'Categorias',
        values: categories,
      },
      {
        type: 'Autores',
        values: authors,
      },
      {
        type: 'Data',
        values: date,
      },
    ];
  };

  useEffect(() => {
    setFilters(getFilters());
  }, []);

  useEffect(() => {
    const { filters, author, year, saveForLater } = checkParams();

    setActiveFilters([
      ...filters,
      ...author,
      ...year,
      saveForLater ? 'save-for-later' : '',
    ]);
  }, [path]);

  return (
    <Wrapper>
      <ButtonSecondary
        onClick={() => {
          document
            .querySelector('.blog__filters')
            .classList.toggle('blog__filters--active');
        }}
      >
        <BsArrowLeft />
      </ButtonSecondary>
      {filters.map((row, index) => {
        return (
          <li>
            <h4>{row.type}:</h4>
            <ul className="filters">
              {row.values.map((element, index) => {
                return (
                  <Item
                    key={index}
                    hasColor={!!element.color}
                    color={secondary[element.color]}
                    className={
                      activeFilters &&
                      activeFilters?.includes(element.slug) &&
                      'active'
                    }
                  >
                    <ButtonGeneric
                      onClick={() => updateFilters(row.type, element.slug)}
                    >
                      {element.name || element.title}
                    </ButtonGeneric>
                  </Item>
                );
              })}
            </ul>
          </li>
        );
      })}
      <Item>
        <ButtonGeneric
          onClick={() =>
            updateFilters(
              'save-for-later',
              !activeFilters?.includes('save-for-later')
            )
          }
        >
          {activeFilters?.includes('save-for-later') ? (
            <BsBookmarkFill />
          ) : (
            <BsBookmark />
          )}
          <span>Save for Later</span>
        </ButtonGeneric>
      </Item>
    </Wrapper>
  );
};

export default Filters;
